<template>
  <div class="device-edit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/device' }">设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改设备</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-width="100px" class="edit-form">
          <el-form-item label="设备名称" prop="title">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="归属病区" prop="address">
            <el-cascader v-model="editForm.belong" placeholder="可直接搜索病区" :options="hospitalAreaData" filterable></el-cascader>
          </el-form-item>
          <el-form-item label="设备图片" prop="img">
            <upload :url="editForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="设备总数" prop="num">
            <el-input-number v-model="editForm.num" controls-position="right" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="设备地址" prop="address">
            <el-input v-model="editForm.address"></el-input>
          </el-form-item>
          <el-form-item label="设备状态" prop="status">
            <el-radio-group v-model="editForm.status">
              <el-radio :label="1" >正常</el-radio>
              <el-radio :label="2" >租借中</el-radio>
              <el-radio :label="3">维修中</el-radio>
            </el-radio-group>
            <div class="tips">提示：非必要，请勿修改设备状态</div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('editRef')">立即修改</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'DeviceEdit',
  components: {
    Upload
  },
  data () {
    return {
      hospitalAreaData: [],
      editForm: {
        title: '',
        belong: [],
        img: '',
        num: 1,
        address: '',
        status: 1
      },
      editRules: {
        title: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        belong: [
          { required: true, message: '请选择归属病区', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传设备图片', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入设备摆放地址', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getSingleInfo()
    this.getHospitalArea()
  },
  methods: {
    // 获取医院和病区的联动数据
    async getHospitalArea () {
      const { data: res } = await this.$http.get('/hospital-and-area')
      if (res.meta.status === 200) {
        this.hospitalAreaData = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getSingleInfo () {
      const { data: res } = await this.$http.get('/device/' + this.$route.params.id)
      if (res.meta.status === 200) {
        this.editForm.title = res.data.title
        this.editForm.belong = res.data.belong
        this.editForm.img = res.data.img
        this.editForm.num = res.data.num
        this.editForm.address = res.data.address
        this.editForm.status = res.data.status
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    backUrl () {
      this.$router.push('/dashboard/device')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/device/' + this.$route.params.id, this.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/device')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
.tips{
  font-size: 12px;
  color: #F56C6C;
}
</style>
